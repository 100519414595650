import { api } from '.';
import { ADVERTISER_RESOURCE } from '@/services/keys';

export const getResources = advertiserId => ({
  [ADVERTISER_RESOURCE]: advertiserId,
});

/**
 *
 * @param {string} advertiserId
 * @returns {Promise.<string>}
 */
export async function deleteAdvertiser(advertiserId) {
  const partialUrl = api.createUrl(getResources(advertiserId));
  await api.delete(partialUrl);
  return advertiserId;
}
