import { api } from '.';
import { PUBLISHER_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import ecommerceEntityParser from '@/entities/ecommerce/EcommerceEntityParser';

export const getResources = publisherId => ({
  [PUBLISHER_RESOURCE]: publisherId,
});
/**
 *
 * @param {string} publisherId
 * @returns {Promise.<{data:Publisher[],meta:import('@/model/shared/ResponseBuilder').EndpointMeta}>}
 */
export default async function (publisherId, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl(getResources(publisherId));
  params.addInclude('category', 'subcategory', 'linkedClients');
  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(ecommerceEntityParser.parse(data), data.meta).build();
}
