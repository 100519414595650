import { api } from '.';
import { PUBLISHER_RESOURCE } from '@/services/keys';

/**
 *
 * @param {Publisher} publisher
 * @returns {Promise.<Object>}
 */
export default async function (publisher) {
  const partialUrl = api.createUrl({
    [PUBLISHER_RESOURCE]: publisher.id,
  });
  return await api.update(partialUrl, publisher.payload());
}
