import { api } from '.';
import { PUBLISHER_RESOURCE, RESET_EXTRA_PARAMS_RESOURCE } from '@/services/keys';

/**
 *
 * @param {String} publisherId
 * @returns {Promise<string>}
 */
export default function (publisherId, clientId) {
  const partialUrl = api.createUrl({
    [PUBLISHER_RESOURCE]: publisherId,
    [RESET_EXTRA_PARAMS_RESOURCE]: undefined,
  });

  return api.update(partialUrl, { clientId });
}
