import { getAdvertisersWithoutIncludes, getAdvertiserById } from '@/services/modules/ecommerce/advertiser';
import { getCampaignsWithoutIncludes, getCampaignById } from '@/services/modules/ecommerce/campaign';
import { getCategoryById } from '@/services/modules/Core/category';
import { getCountryByIso } from '@/services/modules/Core/country';
import { getOffersWithoutIncludes, getOfferById } from '@/services/modules/ecommerce/offer';
import { getPublisherById, getPublishersWithoutIncludes } from '@/services/modules/ecommerce/publisher';
import { ROLES } from '@/model/shared/roles';
import COST_TYPE_OPTIONS from '@/model/modules/ecommerce/offer/CostTypeOptions';
import Filter from '@/model/shared/Filter';
import OFFER_STATUS from '@/model/modules/ecommerce/offer/OfferStatus';
import TYPE_FEED_OPTIONS from '@/model/modules/ecommerce/offer/FeedTypeOptions';
import { PUBLISHER_STATUS } from '@/model/modules/ecommerce/publisher/PublisherStatus';
import SelectOption from '@/model/shared/SelectOption';
import { getUserById, getUsersWithoutIncludes } from '@/services/modules/Core/user';

export const FILTERS_KEYS = {
  ADVERTISER_ID: 'ADVERTISER_ID',
  ADVERTISER_NAME: 'ADVERTISER_NAME',
  AUTHOR_ID: 'AUTHOR_ID',
  CAMPAIGN_ID: 'CAMPAIGN_ID',
  CAMPAIGN_NUMBER: 'CAMPAIGN_NUMBER',
  CATEGORY_ID: 'CATEGORY_ID',
  COUNTRY_ISO: 'COUNTRY_ISO',
  CREATED_AT: 'CREATED_AT',
  NAME: 'NAME',
  OFFER_ID: 'OFFER_ID',
  OFFER_NAME: 'OFFER_NAME',
  OFFER_STATUS: 'OFFER_STATUS',
  PAYMENT: 'PAYMENT',
  PUBLISHER_ID: 'PUBLISHER_ID',
  PUBLISHER_NAME: 'PUBLISHER_NAME',
  PUBLISHER_STATUS: ' PUBLISHER_STATUS',
  SUB_CATEGORY_ID: 'SUB_CATEGORY_ID',
  SUB_PUBLISHER_CATEGORY: 'SUB_PUBLISHER_CATEGORY',
  TYPE: 'TYPE',
  USER_ID: 'USER_ID',
};

// TODO: we need to talk to backend to normalize filter name
export default Object.freeze({
  CREATED_AT: new Filter(FILTERS_KEYS.CREATED_AT, 'Created at', 'createdAt'),
  NAME: new Filter(FILTERS_KEYS.NAME, 'Name', 'name'),
  CAMPAIGN_NUMBER: new Filter(FILTERS_KEYS.CAMPAIGN_NUMBER, 'Campaign ID', 'number'),
  OFFER_NAME: new Filter(FILTERS_KEYS.OFFER_NAME, 'Name', 'name'),
  PUBLISHER_NAME: new Filter(FILTERS_KEYS.PUBLISHER_NAME, 'Name', 'name'),
  AUTHOR_ID: new Filter(FILTERS_KEYS.AUTHOR_ID, 'Author', 'authorId')
    .asMultiple()
    .setOptions(getUsersWithoutIncludes)
    .getItemBy(getUserById)
    .setAllowedRoles(ROLES.ADMIN, ROLES.CLIENT, ROLES.AD_OPS),
  ADVERTISER_ID: new Filter(FILTERS_KEYS.ADVERTISER_ID, 'Advertiser', 'advertiser.id')
    .setOptions(getAdvertisersWithoutIncludes)
    .getItemBy(getAdvertiserById),
  ADVERTISER_NAME: new Filter(FILTERS_KEYS.ADVERTISER_NAME, 'Advertiser', 'advertiser.name', 'name', 'name')
    .setOptions(getAdvertisersWithoutIncludes)
    .getItemBy(getAdvertiserById),
  ANALYTICS_ADVERTISER_ID: new Filter(FILTERS_KEYS.ADVERTISER_ID, 'Advertiser', 'advertiserId')
    .asMultiple()
    .setOptions(getAdvertisersWithoutIncludes)
    .getItemBy(value => getAdvertiserById(value?.id || value))
    .allowSeveralValues(),
  PUBLISHER_STATUS: new Filter(FILTERS_KEYS.PUBLISHER_STATUS, 'Status', 'status')
    .setOptions(PUBLISHER_STATUS)
    .asMultiple(),
  OFFER_STATUS: new Filter(FILTERS_KEYS.OFFER_STATUS, 'Status', 'status', 'name', 'value')
    .setOptions(OFFER_STATUS)
    .asMultiple(),
  CATEGORY_ID: new Filter(FILTERS_KEYS.CATEGORY_ID, 'Category', 'category.id').getItemBy(getCategoryById).asMultiple(),
  SUB_CATEGORY_ID: new Filter(FILTERS_KEYS.SUB_CATEGORY_ID, 'Sub Category', 'subcategory.id')
    .getItemBy(getCategoryById)
    .asMultiple(),
  COUNTRY_ISO: new Filter(FILTERS_KEYS.COUNTRY_ISO, 'Country', 'countryIso')
    .getItemBy(getCountryByIso)
    .asMultiple()
    .allowSeveralValues(),
  PAYMENT: new Filter(FILTERS_KEYS.PAYMENT, 'Payment', 'priceType', 'name', 'value')
    .setOptions(COST_TYPE_OPTIONS)
    .asMultiple(),
  PUBLISHER_ID: new Filter(FILTERS_KEYS.PUBLISHER_ID, 'Publisher', 'publisherId')
    .asMultiple()
    .setOptions(getPublishersWithoutIncludes)
    .getItemBy(value => getPublisherById(value?.id || value))
    .setAllowedRoles(ROLES.ADMIN, ROLES.CLIENT, ROLES.AD_OPS)
    .allowSeveralValues(),
  OFFER_ID: new Filter(FILTERS_KEYS.OFFER_ID, 'Offer', 'offerId')
    .asMultiple()
    .setOptions(getOffersWithoutIncludes)
    .getItemBy(value => getOfferById(value?.id || value))
    .allowSeveralValues(),
  OFFER_ID2: new Filter(FILTERS_KEYS.OFFER_ID, 'Offer', 'offer.id')
    .asMultiple()
    .setOptions(getOffersWithoutIncludes)
    .getItemBy(value => getOfferById(value?.id || value)),
  OFFER_NAME_SERVICE: new Filter(FILTERS_KEYS.OFFER_NAME, 'Offer', 'offer.name', 'name', 'name')
    .asMultiple()
    .setOptions(getOffersWithoutIncludes)
    .getItemBy(value => getOfferById(value?.id || value)),
  CAMPAIGN_ID: new Filter(FILTERS_KEYS.CAMPAIGN_ID, 'Campaign', 'campaignId')
    .asMultiple()
    .setOptions(getCampaignsWithoutIncludes)
    .getItemBy(getCampaignById)
    .setAllowedRoles(ROLES.ADMIN, ROLES.CLIENT, ROLES.AD_OPS)
    .allowSeveralValues(),
  FEED_TYPE: new Filter(FILTERS_KEYS.TYPE, 'Type', 'type', 'name', 'value').setOptions(TYPE_FEED_OPTIONS),
  CONTEXT_ROLE: new Filter('contextRole.role', 'role', 'contextRole.role').setOptions([
    new SelectOption(ROLES.PUBLISHER.id, ROLES.PUBLISHER.name),
  ]),
  USER_ID: new Filter(FILTERS_KEYS.USER_ID, 'User', 'userId')
    .asMultiple()
    .setOptions(getUsersWithoutIncludes)
    .getItemBy(getUserById)
    .setAllowedRoles(ROLES.ADMIN, ROLES.CLIENT, ROLES.AD_OPS),
  USER_PUBLISHER_LINK: new Filter('userPublisherLink.publisherId', 'publisher', 'userPublisherLink.publisherId')
    .setOptions(getPublishersWithoutIncludes)
    .getItemBy(value => getPublisherById(value?.id || value))
    .setAllowedRoles(ROLES.ADMIN, ROLES.AD_OPS),
  SUB_PUBLISHER_CATEGORY: new Filter(
    FILTERS_KEYS.SUB_PUBLISHER_CATEGORY,
    'Category',
    'subPublisherCategory'
  ).setAllowedRoles(ROLES.ADMIN, ROLES.AD_OPS, ROLES.CLIENT, ROLES.SUPER_ADVERTISER),
});
