/// <reference path="../../../../entities/ecommerce/Advertiser.js" />

import { api } from '.';
import { ADVERTISER_RESOURCE } from '@/services/keys';

export const getResources = advertiserId => ({
  [ADVERTISER_RESOURCE]: advertiserId,
});

/**
 *
 * @param {Advertiser} advertiser
 * @returns {Promise.<any>}
 */
export function updateAdvertiser(advertiser) {
  const partialUrl = api.createUrl(getResources(advertiser.id));
  return api.update(partialUrl, advertiser.payload());
}
