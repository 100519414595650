import { getPublishers, getPublishersWithoutIncludes } from './getPublishers';
import getPublisherById from './getPublisherById';
import updatePublisher from './updatePublisher';
import createPublisher from './createPublisher';
import resetExtraParamsTrackerLink from './resetExtraParamsTrackerLink';
import { ecommerce } from '..';

export const api = ecommerce;

export {
  getPublishers,
  getPublisherById,
  updatePublisher,
  createPublisher,
  getPublishersWithoutIncludes,
  resetExtraParamsTrackerLink,
};
