// import { createAdvertiser } from './createAdvertiser';
import { deleteAdvertiser } from './deleteAdvertiser';
import { updateAdvertiser } from './updateAdvertiser';
import { createAdvertiser } from './createAdvertiser';
import { getAdvertiserById } from './getAdvertiserById';
import { getAdvertisers, getAdvertisersWithoutIncludes } from './getAdvertisers';
import { ecommerce } from '..';

export const api = ecommerce;

export {
  createAdvertiser,
  deleteAdvertiser,
  updateAdvertiser,
  getAdvertiserById,
  getAdvertisers,
  getAdvertisersWithoutIncludes,
};
