import { api } from '.';
import { PUBLISHER_RESOURCE } from '@/services/keys';

/**
 *
 * @param {Publisher} publisher
 * @returns {Promise<string>}
 */
export default async function (publisher) {
  const partialUrl = api.createUrl({
    [PUBLISHER_RESOURCE]: undefined,
  });
  const {
    data: { publisher: id },
  } = await api.create(partialUrl, publisher.payload());
  return id;
}
