import { api } from '.';
import { PUBLISHER_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import ecommerceEntityParser from '@/entities/ecommerce/EcommerceEntityParser';

/**
 *
 * @param {QueryParamsBuilder} params
 * @param {String} clientId
 * @returns {Promise.<{data:Publisher[],meta:import('@/model/shared/ResponseBuilder').EndpointMeta}>}
 */
export async function getPublishers(params = new QueryParamsBuilder(), clientId = undefined) {
  const partialUrl = api.createUrl({
    [PUBLISHER_RESOURCE]: undefined,
  });
  params.addInclude('category', 'subcategory');
  params.addFilter('client.id', clientId);
  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(ecommerceEntityParser.parse(data), data.meta).build();
}

/**
 *
 * @param {QueryParamsBuilder} params
 * @param {String} clientId
 * @returns {Promise.<{data:Publisher[],meta:import('@/model/shared/ResponseBuilder').EndpointMeta}>}
 */
export async function getPublishersWithoutIncludes(params = new QueryParamsBuilder(), clientId = undefined) {
  const partialUrl = api.createUrl({
    [PUBLISHER_RESOURCE]: undefined,
  });
  params.addFilter('client.id', clientId);
  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(ecommerceEntityParser.parse(data), data.meta).build();
}
