/// <reference path="../../../../entities/ecommerce/Advertiser.js" />

import { api } from '.';
import { ADVERTISER_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ecommerceEntityParser from '@/entities/ecommerce/EcommerceEntityParser';
import ResponseBuilder from '@/model/shared/ResponseBuilder';

/**
 * @typedef Relationships
 * @property {{data:{type:string,id:string}}} any - Relationships object name
 *
 * @typedef EndpointData
 * @property {string} id
 * @property {object} attributes
 * @property {Relationships} [relationships]
 */

export const getResources = advertiserId => ({
  [ADVERTISER_RESOURCE]: advertiserId,
});

/**
 *
 * @param {string} advertiserId
 * @returns {Promise.<Advertiser>}
 */
export async function getAdvertiserById(advertiserId) {
  const partialUrl = api.createUrl(getResources(advertiserId));

  const params = new QueryParamsBuilder();
  params.addInclude(['subcategory', 'activeOffersCount', 'category']);

  const { data } = await api.get(partialUrl, params);
  return new ResponseBuilder(ecommerceEntityParser.parse(data), data.meta).build();
}
