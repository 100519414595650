const COST_TYPES = {
  CPC: 'CPC',
  CPA: 'CPA',
  CPM: 'CPM',
};

const COST_TYPE_OPTIONS = [
  { name: COST_TYPES.CPC, value: COST_TYPES.CPC, percentage: false },
  { name: COST_TYPES.CPA, value: COST_TYPES.CPA, percentage: true },
  { name: COST_TYPES.CPM, value: COST_TYPES.CPM, percentage: false },
];

export { COST_TYPES };
export default COST_TYPE_OPTIONS;
